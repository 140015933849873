<template>
  <el-dialog top="3vh" :visible.sync="showDialog" width="760px">
    <div slot="title" style="text-align: center" class="slot-title">
      <span style="background: #eceafa; padding: 5px">
        {{ detail.feedbackTypeName }}</span
      >
    </div>
    <div class="dialog">
      <div class="flex-row flex-ac">
        <div class="title">用户名称</div>
        <el-input
          v-model="detail.username"
          style="width: 200px"
          size="mini"
          readonly="true"
        ></el-input>
      </div>
      <div class="flex-row flex-ac">
        <div class="title">剧本名称</div>
        <el-input
          v-model="detail.scenarioName"
          style="width: 200px"
          size="mini"
          readonly="true"
        ></el-input>
      </div>
      <div class="flex-row flex-ac">
        <div class="title">反馈类型</div>
        <el-input
          v-model="detail.feedbackTypeName"
          style="width: 200px"
          size="mini"
          readonly="true"
        ></el-input>
      </div>
      <div class="flex-row flex-as p-r">
        <div class="title">反馈内容</div>
        <el-input
          v-model="detail.feedbackContent"
          style="width: 430px"
          size="mini"
          type="textarea"
          readonly="true"
        ></el-input>
      </div>
    </div>
  </el-dialog>
</template>

<script>
export default {
  props: {
    detail: {
      default: () => {}
    },
  },
  data() {
    return {
      showDialog: false
    };
  },
  created() {},
  methods: {},
  computed: {},
  watch: {},
};
</script>

<style scoped lang="scss">
@import "@style/form.scss";
</style>