<template>
  <div class="h-100">
    <div class="flex-cloumn h-100">
      <!-- 筛选表单 -->
      <div class="select-wrap">
        <div style="display: flex; align-items: center">
          <div>反馈类型：</div>
          <el-select
            v-model="tableFilter1.feedBackType"
            style="width: 200px"
            class="select-item"
            size="mini"
            placeholder="反馈类型"
          >
            <el-option
              v-for="option in feedbackTypes"
              :key="option.id"
              :label="option.name"
              :value="option.id"
            ></el-option>
          </el-select>
        </div>
        <el-input
          style="width: 226px"
          placeholder="搜索关键字"
          size="mini"
          v-model="tableFilter1.query"
        ></el-input>
      </div>
      <div class="row2 flex-1">
        <el-card class="row2-card" v-loading="loading">
          <!-- 表头 -->
          <div class="flex-row table-header" style="padding-left: 60px">
            <div style="width: 120px; text-align: left">用户名称</div>
            <div style="width: 136px">剧本名称</div>
            <div style="width: 130px">反馈类型</div>
            <div style="width: 194px">反馈内容</div>
            <div style="width: 80px">机型</div>
            <div
              style="width: 150px; padding-left: 10px"
              class="relative-left-10"
            >
              创建日期
            </div>
          </div>
          <!-- 行数据 -->
          <div
            class="item"
            @click="openDialog('showFeedback', item)"
            v-for="(item, i) in list"
            :key="i"
            :style="{
              background: i % 2 !== 0 ? '#fff' : 'rgba(250,249,255,1)',
            }"
          >
            <!-- <el-checkbox></el-checkbox> -->
            <!-- 商品序号 -->
            <div class="item-index">
              {{ i + 1 }}
            </div>
            <div class="flex-row space-around flex-1">
              <!-- 名称 -->
              <div class="item-title shenglue-1" style="width: 120px">
                {{ item.username }}
              </div>
              <div class="item-message shenglue-1" style="width: 136px">
                {{ item.scenarioName }}
              </div>
              <div
                class="item-message shenglue-1"
                style="width: 80px; text-align: center"
              >
                {{ item.feedbackTypeName }}
              </div>
              <el-popover
                placement="right-end"
                title=""
                width="200"
                trigger="hover"
                :open-delay="500"
                :content="item.feedbackContent"
              >
                <div
                  slot="reference"
                  class="item-message shenglue-1"
                  style="width: 200px; text-align: center"
                >
                  {{ item.feedbackContent }}
                </div>
              </el-popover>


              <!-- 机型 -->
               <el-popover
                placement="right-end"
                title=""
                width="200"
                trigger="hover"
                :open-delay="500"
                :content="item.client"
              >
                <div
                  slot="reference"
                  class="item-message shenglue-1"
                  style="width: 80px; text-align: center"
                >
                  {{ item.client }}
                </div>
              </el-popover>
              
              <!-- <div slot="reference" class="item-message shenglue-1" style="width: 280px; text-align: center">
                {{ item.feedbackContent }}
              </div> -->
              <div class="item-date shenglue-1" style="width: 150px">
                <div class="state">
                  {{ item.feedbackTime }}
                </div>
              </div>
            </div>
          </div>
        </el-card>
      </div>
    </div>
    <div class="row3">
      <el-pagination
        :total="total"
        :current-page.sync="tableFilter1.currPage"
        :page-size.sync="tableFilter1.limit"
        layout="prev, pager, next"
      ></el-pagination>
    </div>
    <showFeedback ref="showFeedback" :detail="feedbackDetail" />
  </div>
</template>
<script>
import showFeedback from "./common/component/showFeedback";
export default {
  components: {
    showFeedback,
  },
  data() {
    return {
      loading: false,
      // tab栏
      curTab: 1,
      feedbackDetail: {},
      // 搜索
      tableFilter1: {
        currPage: 1,
        limit: 35,
        query: "",
        feedBackType: 0,
      },
      list: [
        {
          name: "啥的静安寺奥术大师",
          content:
            "骄傲是激动啊就搜到骄傲是激动啊搜到涉及到谁懂啊搜集冻结爱搜激动啊搜东京暗搜达搜集都按时",
          score: "9",
          status: true,
          date: "2020-05-05",
        },
      ],
      total: 100,
      commentDetail: {},
      feedbackTypes: [
        { name: "全部", id: 0 },
        { name: "剧本", id: 1 },
        { name: "游戏", id: 2 },
        { name: "消费", id: 3 },
      ],
    };
  },
  async created() {
    this.getFeedbackList();
  },
  methods: {
    async getFeedbackList() {
      this.loading = true;
      const data = await this.$api.message
        .getFeedbackList(this.tableFilter1)
        .finally(() => {
          this.loading = false;
        });
      this.list = data.data;
      this.total = data.count;
    },
    openDialog(ref, detail = null) {
      this.feedbackDetail = detail;
      this.$refs[ref].showDialog = true;
    },
  },
  watch: {
    tableFilter1: {
      handler() {
        this.getFeedbackList();
      },
      deep: true,
    },
  },
  computed: {},
};
</script>
<style lang="scss" scoped>
@import "./common/style/shop.scss";
@import "./common/style/form.scss";
.item-date {
  text-align: left;
}
</style>